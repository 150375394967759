<div *ngIf="role !== 5 && role !== 3" class="grey-background">
  <div class="blue-background back-container">
  </div>
  <div class="row">
    <div class="white-background container-row center-container container-border col-md-10">
      <div class="row container-title">
        <div class="col-md-12">
          {{ 'TAX/title' | translate }}
          <button *ngIf="role === 1" mat-icon-button matTooltip="Clique para visualizar informações da MDR relacionadas a custos e taxas" [matMenuTriggerFor]="menu">
            <mat-icon style="margin-bottom:5px">info</mat-icon>
          </button>
          <mat-menu #menu="matMenu">
            <button (click)="openMdrFinance()" mat-menu-item > Visualizar linhas da MDR no Finance </button>
            <button (click)="openHistoryModal()" mat-menu-item> Visualizar alterações de Frete e taxas </button>
          </mat-menu>
        </div>
      </div>
      <hr>

      <!-- Extra Taxes -->
      <div class="row">
        <div class="col-6">
          <span class="title" style="margin-left:-10px">{{ 'TAX/ordinary' | translate }}</span>
        </div>
        <div class="col-6" style="text-align:right">
          <button mat-icon-button (click)="download()">
            <i class="fas fa-file-download"></i>
          </button>
        </div>
      </div>
      <!-- fiscal in & out state -->
      <div class="row">
        <!-- inState -->
        <div class="col-md-6">
          <mat-form-field class="col-md-10">
            <mat-label>{{ 'TAX/fiscal-state/in' | translate }}</mat-label>
            <mat-select [(ngModel)]="mdr.fiscalInState" name="inState"
              [disabled] = 'icmsState.in.editing === false'
            >
              <mat-option *ngFor="let item of icmsState.in.all" [value]="item">
                {{item}}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <i font class="fa fa-pencil-alt icon-header click-button"
            *ngIf="
            role === 1
            &&
            [MDR_STATUS.VEICULO_CARREGADO,MDR_STATUS.MOTORISTA_E_VEICULOS_DEFINIDOS,MDR_STATUS.MDR_AGUARDANDO_LSP,MDR_STATUS.NOVA_MDR,MDR_STATUS.MDR_EM_CRIACAO,MDR_STATUS.MDR_APROVADA_LSP].indexOf(mdr.status) !== -1
            &&
            icmsState.in.editing === false
            "
            (click)="editState('in')"
            aria-hidden="true"
          ></i>
          <!-- edit button -->
          <i font class="fa fa-save icon-header click-button"
            *ngIf="
            role === 1
            &&
            [MDR_STATUS.VEICULO_CARREGADO,MDR_STATUS.MOTORISTA_E_VEICULOS_DEFINIDOS,MDR_STATUS.MDR_AGUARDANDO_LSP,MDR_STATUS.NOVA_MDR,MDR_STATUS.MDR_EM_CRIACAO,MDR_STATUS.MDR_APROVADA_LSP].indexOf(mdr.status) !== -1
            &&
            icmsState.in.editing === true
            "
            (click)="saveState('in')"
            aria-hidden="true"
          ></i>
        </div>
        <!-- outState -->
        <div class="col-md-6">
          <mat-form-field class="col-md-10">
            <mat-label>{{ 'TAX/fiscal-state/out' | translate }}</mat-label>
            <mat-select [(ngModel)]="mdr.fiscalOutState" name="outState"
              [disabled] = 'icmsState.out.editing === false'
            >
              <mat-option *ngFor="let item of icmsState.out.all" [value]="item">
                {{item}}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <i font class="fa fa-pencil-alt icon-header click-button"
            *ngIf="
            role === 1
            &&
            [MDR_STATUS.VEICULO_CARREGADO,MDR_STATUS.MOTORISTA_E_VEICULOS_DEFINIDOS,MDR_STATUS.MDR_AGUARDANDO_LSP,MDR_STATUS.NOVA_MDR,MDR_STATUS.MDR_EM_CRIACAO,MDR_STATUS.MDR_APROVADA_LSP].indexOf(mdr.status) !== -1
            &&
            icmsState.out.editing === false
            "
            (click)="editState('out')"
            aria-hidden="true"
          ></i>
          <!-- edit button -->
          <i font class="fa fa-save icon-header click-button"
            *ngIf="
            role === 1
            &&
            [MDR_STATUS.VEICULO_CARREGADO,MDR_STATUS.MOTORISTA_E_VEICULOS_DEFINIDOS,MDR_STATUS.MDR_AGUARDANDO_LSP,MDR_STATUS.NOVA_MDR,MDR_STATUS.MDR_EM_CRIACAO,MDR_STATUS.MDR_APROVADA_LSP].indexOf(mdr.status) !== -1
            &&
            icmsState.out.editing === true
            "
            (click)="saveState('out')"
            aria-hidden="true"
          ></i>
        </div>
      </div>

      <div class="row" *ngIf="nfePrice !== undefined" >
        <mat-form-field class="col-md-12">
          <input disabled matInput type="text" placeholder="{{ 'TAX/value_nf_system' | translate }}" [ngModel]="nfePrice | currency:'BRL'">
        </mat-form-field>
      </div>
      <div class="row">
        <div class="col-12" style="text-align:right">
          <!-- edit values -->
          <i font class='fa fa-percent' style="font-size: 12px; margin-left: 10px;" aria-hidden="true" (click)='showCalculations()'></i>
          <i (click)="editValues()"
            *ngIf="
            (role === 1 || role === 12 || role === 14)
            &&
            [MDR_STATUS.MDR_PAGA_CONCLUIDA,MDR_STATUS.ENTREGA_DE_CARGA_CERTIFICADA_PELO_LSP].indexOf(mdr.status) === -1
            &&
            editing_freight === false
            "
            matTooltip="{{ 'TAX/edit-values' | translate }}"
            font
            class="fa fa-pencil-alt click-button"
            style="font-size: 12px; margin-left: 10px;"
            aria-hidden="true"
          ></i>
          <!-- save values -->
          <i (click)="saveValues()"
            *ngIf="
            (role === 1 || role === 12 || role === 14)
            &&
            [MDR_STATUS.MDR_PAGA_CONCLUIDA,MDR_STATUS.ENTREGA_DE_CARGA_CERTIFICADA_PELO_LSP].indexOf(mdr.status) === -1
            &&
            editing_freight === true
            "
            matTooltip="{{ 'TAX/save-values' | translate }}"
            font
            class="fa fa-save click-button"
            style="font-size: 12px; margin-left: 10px;"
            aria-hidden="true"
          ></i>
          <!-- recalculate values -->
          <i (click)="getAllPriceWithKm()"
            *ngIf="editing_freight === true"
            matTooltip="{{ 'TAX/recalculate' | translate }}"
            font
            class="fa fa-calculator click-button"
            style="font-size: 12px; margin-left: 10px;"
            aria-hidden="true"
          ></i>
        </div>
      </div>
      <div class="row" *ngIf="price !== undefined" >
        <mat-form-field
          *ngIf="role !== 5"
          class="col-md-3"
        >
          <input matInput #input1
            *ngIf="update"
            type="text"
            [disabled]="editing_freight === false"
            placeholder="{{ 'TAX/value_nf' | translate }}"
            [ngModel]="mdr.totalNF | currency:'BRL'"
            (blur)="sanitizeInputMdr('totalNF', $event, input1)"
          >
        </mat-form-field>
        <div *ngIf="role === 5" class="col-md-3">
          <p>Valor Total de NF:<span> {{mdr.totalNF | currency:'BRL'}}</span></p>
        </div>
        <mat-form-field
          *ngIf="role !== 5"
          class="col-md-3"
        >
          <input matInput
            type="text"
            [disabled]="editing_freight === false"
            placeholder="{{ 'TAX/total_km' | translate }}"
            [(ngModel)]="mdr.totalKM"
          >
        </mat-form-field>
        <div *ngIf="role === 5" class="col-md-3">
          <p>Distância:<span> {{mdr.totalKM}} KM</span></p>
        </div>
        <mat-form-field
          *ngIf="(role !== 5 && role !== 12 && role !== 14) && calculationsShouldBeShown"
          class="col-md-3"
        >
          <input matInput #input2
            *ngIf="update"
            type="text"
            [disabled]="editing_freight === false"
            placeholder="{{ 'TAX/freight' | translate }}"
            [ngModel]="price.price | currency:'BRL'"
            (blur)="sanitizeInputPrice('price', $event, input2)"
          >
        </mat-form-field>
        <div *ngIf="(role === 5 || role === 12 || role === 14) && calculationsShouldBeShown" class="col-md-3">
          <p>Frete:<span> {{price.price | currency:'BRL'}}</span></p>
        </div>
      </div>
      <div class="row" style="color: darkgray;" *ngIf="calculationsShouldBeShown">
        <!-- <div class="col-md-12">
          <p>Serviço:<span>Frete Rodoviário Preço por Quilometro (todos os custos considerados ex: pedágio, combustível, etc). Frete de retorno (ponte de entrega - ponto de coleta) será pago 50% do frete de ida.</span></p>
        </div> -->
        <div class="col-md-3">
          <p>Região:<span>{{ region }}</span></p>
        </div>
        <div class="col-md-2">
          <p>Imposto: <span>{{ (price.taxedPrice - price.price) /price.taxedPrice | percent: '0.0-1'}}</span></p>
        </div>
        <div class="col-md-3">
          <p *ngIf="mdr.totalKM <= 150">Preço Mínimo: <span>{{price.price | currency:'BRL'}}</span></p>
          <p *ngIf="mdr.totalKM > 150">Preço Por KM: <span>{{price.price/ mdr.totalKM | currency:'BRL'}}</span></p>
        </div>
        <div class="col-md-2">
          <p>% Ad Valorem: <span>{{ price.adValorem /mdr.totalNF | percent: '0.3-3'}}</span></p>
        </div>
      </div>
      <div class="row" *ngIf="price !== undefined">
        <mat-form-field
          *ngIf="(role !== 5  && role !== 12 && role !== 14) && calculationsShouldBeShown"
          class="col-md-3"
        >
          <input matInput #input4
            *ngIf="update"
            type="text"
            [disabled]="editing_freight === false"
            placeholder="{{ 'TAX/tax_freight' | translate }}"
            [ngModel]="price.taxedPrice | currency:'BRL'"
            (blur)="sanitizeInputPrice('taxedPrice',$event, input4)"
          >
        </mat-form-field>
        <div *ngIf="(role === 5 || role === 12 || role === 14) && calculationsShouldBeShown" class="col-md-3">
          <p>Frete com Taxas:<span> {{price.taxedPrice | currency:'BRL'}}</span></p>
        </div>
        <mat-form-field
          *ngIf="(role !== 5  && role !== 12 && role !== 14) && calculationsShouldBeShown"
          class="col-md-3"
        >
          <input matInput #input5
            *ngIf="update"
            type="text"
            [disabled]="editing_freight === false"
            placeholder="{{ 'TAX/ad_valorem' | translate }}"
            [ngModel]="price.adValorem | currency:'BRL'"
            (blur)="sanitizeInputPrice('adValorem', $event, input5)"
          >
        </mat-form-field>
        <div *ngIf="(role === 5 || role === 12 || role === 14) && calculationsShouldBeShown" class="col-md-3">
          <p>Ad Valorem:<span> {{price.adValorem | currency:'BRL'}}</span></p>
        </div>
        <!-- <mat-form-field
          *ngIf="role !== 5  && role !== 12 && role !== 14"
          class="col-md-3"
        >
          <input matInput #input6
            *ngIf="update"
            type="text"
            [disabled]="editing_freight === false"
            placeholder="{{ 'TAX/gris' | translate }}"
            [ngModel]="price.gris | currency:'BRL'"
            (blur)="sanitizeInputPrice('gris',$event, input6)"
          >
        </mat-form-field> -->
        <!-- <div *ngIf="role === 5 || role === 12  || role === 14" class="col-md-3">
          <p>Gris:<span> {{price.gris | currency:'BRL'}}</span></p>
        </div> -->
      </div>
      <div class="row" *ngIf="price !== undefined">
        <mat-form-field
          *ngIf="role !== 5 && role !== 12 && role !== 14"
          class="col-md-6"
        >
          <input matInput #input7
            *ngIf="update"
            type="text"
            [disabled]="editing_freight === false"
            placeholder="{{ 'TAX/total' | translate }}"
            [ngModel]="price.total | currency:'BRL'"
            (blur)="sanitizeInputPrice('total', $event, input7)"
          >
        </mat-form-field>
        <div class="col-md-5">
          <p *ngIf="role === 5 || role === 12  || role === 14" >Total: <span> {{price.total | currency:'BRL'}}</span></p>
        </div>
        <div class="col-md-1" style="text-align:right">
          <!-- <i class="fa" [ngClass]="payField.color ? [ payment.icons[payField.color] , payment.classes[payField.color] ] : ''" matSuffix [matTooltip]="payment.tooltips[payField.color]"></i> -->
        </div>
      </div>

      <!-- UOC (additional taxes) -->
      <div class="row">
        <div class="col-md-9" class="pad">
          <span class="title">{{ 'TAX/uoc' | translate }}</span>
        </div>
      </div>
      <div *ngFor="let UOC of UOCs;let i = index;">
        <div class="row" >
        <p class="col-md-5"> {{UOC.uoc}}</p>
        <p class="col-md-3">{{UOC.value | currency: 'BRL'}}</p>
        <p class="col-md-2">id: {{UOC.occurrenceId}}</p>
        <p class="col-md-1">
        <i class="fa fa-times" (click)='removeUOC(UOC)' *ngIf="role === 1 || role === 12  || role === 14"></i>
        </p>
        <p class="col-md-1" style="text-align:right">
        <i class="fa" [ngClass]="[ payment.icons[UOC.color] , payment.classes[UOC.color] ]" matSuffix [matTooltip]="payment.tooltips[UOC.color]"></i>
        </p>
       </div>
       <div class="row" style="color: darkgray;" *ngIf="calculationsShouldBeShown">
        <div class="col-md-12">
          <p>Serviço: <span>{{UOC.uoc}}</span></p>
        </div>
        <div class="col-md-3">
          <p>Região:<span>{{ region }}</span></p>
        </div>
        <div class="col-md-3">
          <p>Preço:<span> {{(UOC.value)- ((price.taxedPrice - price.price) /price.taxedPrice)*(UOC.value) | currency: 'BRL'}} </span></p>
        </div>
        <div class="col-md-2">
          <p>Imposto:<span>{{ (price.taxedPrice - price.price) /price.taxedPrice | percent}}</span></p>
        </div>
        
      </div>
      </div>

      <!-- Value -->
      <div class="row">
        <div class="col-md-9">
        <span class="title-taxa">{{ 'TAX/uoc_total' | translate }} </span>
        <span class="data-taxa">{{sumUOC*100 | currency:'BRL'}}</span>
        </div>
      </div>

      <!-- CoPQs & CostAvoidance -->
      <div *ngIf="role !== 5">
        <div class="row">
          <div class="col-md-9" class="pad">
            <span class="title">{{ 'TAX/copq' | translate }}</span>
          </div>
        </div>
        <div *ngFor="let COPQ of COPQs; let i = index;">
          <div class ="row">
            <p class="col-md-6"> {{COPQs[i].copq}}</p>
            <p class="col-md-3">{{COPQs[i].value | currency: 'BRL'}}</p>
            <p class="col-md-2"><i class="fa fa-times" (click)='removeCOPQ(COPQs[i])' *ngIf="role === 1"></i></p>
          </div>
        </div>
        <div class="row" >
          <div class="col-md-9">
            <span class="title-taxa">{{ 'TAX/copq_total' | translate }} </span>
            <span class="data-taxa">{{ sumCOPQ | currency:'BRL' }}</span>
          </div>
        </div>
        <div *ngIf="role !== 5  && role !== 12 && role !== 14">
          <div class="row">
            <div class="col-md-9" class="pad">
              <span class="title">{{ 'TAX/COST_AVOIDANCE' | translate }}</span>
              <button class='button green-button text-white' (click)='addCA()'>{{ 'TAX/COST_AVOIDANCE/add' | translate}}</button>
            </div>
          </div>
          <div *ngFor="let ca of costAvoidances; let i = index;">
            <div class ="row">
              <p class="col-md-6"> {{ca.costAvoidance}}</p>
              <p class="col-md-3">{{ca.value/100 | currency: 'BRL'}}</p>
              <p class="col-md-2"><i class="fa fa-times" (click)='removeCostAvoidance(ca)' *ngIf="role === 1"></i></p>
            </div>
          </div>
          <div class="row" >
            <div class="col-md-9">
              <span class="title-taxa">Total: </span>
              <span class="data-taxa">{{ sumAvoid | currency:'BRL' }}</span>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</div>
